<template>
    <div>
        <v-card class="card flat-card" color="surface">
        <v-card-title>
          <h6 class="primaryText--text">تحویل گیرنده</h6>
        </v-card-title>
        <v-card-text>
            <v-row class="mb-4">
            <div class="d-flex mr-4">
                <span class="mr-1 onSurface--text medium-font" v-text="address.receiver"></span>
            </div>
            </v-row>
            <v-row class="mb-4">
                <div class=" mr-4">
                    <span class="onSurface--text medium-font">نشانی :</span>
                    <span class=" mr-1 onSurface--text medium-font" v-text="address.address"></span>
                </div>
            </v-row>
            <v-row class="mb-4">
                <div class=" mr-4">
                    <span class="onSurface--text medium-font">تلفن همراه :</span>
                    <span class="mr-1 onSurface--text medium-font" v-text="address.mobail"></span>
                </div>
            </v-row>
            <v-row class="mb-4">
                <div class=" mr-4">
                    <span class="onSurface--text medium-font">تلفن ثابت :</span>
                    <span class=" mr-1 onSurface--text medium-font" v-text="address.phone"></span>
                </div>
            </v-row>
        </v-card-text>
      </v-card> 
    </div>
</template>

<script>
export default {
    props: ['address']
}
</script>